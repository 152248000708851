import { createSelector } from 'reselect';
import {
	getAppMenuLinks,
	getKinositeSectionLink,
	MOBILE_MAX_SIZE,
	getImaxLinkMonitor,
	getVipLinkMonitor,
	TABLET_MAX_SIZE,
	getKinositeSectionName,
	MENU_LINK_DEFAULT_SORT_ORDER,
	SUPPORTED_LANGS,
} from 'app/constants/AppConstants';
import {
	CINEMA_ID as kinositeCinemaId,
	CITY_ID,
	BUSINESS_DAY_END,
} from 'app/constants/SettingsConstants';
import { getCustomPages } from 'app/selectors/MenuSelectors';
import { checkIfAboutCinemaNotEmpty } from 'app/utils/AppSelectorHelpers';
import { IReduxState } from 'app/types/state';
import { Moment } from 'moment';
import { Cinema, ContactData, IMenu, Modules, Option, Phone, SectionDisplaySettings } from 'app/types/common';
import { currencySymbols } from 'app/constants/CurrencyConstants';
import { normalizeResponseData, sortMonitorCinemas, transformMenuLinks } from 'app/selectors/Helpers';
import { AppErrorCode } from 'app/types/appReducer';
import { getAgeRatings, getReleasesFeatures, getMappedReleasesGenres, hasProCultureSeanses } from 'app/selectors/AfishaSelectors';
import { getCinemasFilterValues, getCurrentPage, getFilters, getLocalFilters, getSortTypesLabels } from 'app/modules/filters/redux/selectors';
import { FILTER_PANEL_ELEMENTS, FilterPanelElementsType, FilterValueType, IFilterValue, IFiltersState, FiltersPage } from 'app/modules/filters/types';
import { getCinemas } from 'app/selectors/ReleaseSelectors';
import { getSeancesOfReleaseItem } from 'app/selectors/ReleaseItemSelectors';
import { KinositeSections } from 'app/types/common';

export const getCinema = (state: IReduxState) => state.appReducer.cinema;
export const getAlert = (state: IReduxState) => state.appReducer.alert;
export const getBranding = (state: IReduxState) => state.appReducer.branding;
export const getContacts = (state: IReduxState) => state.appReducer.contacts;
export const getAboutCinema = (state: IReduxState) => state.appReducer.aboutCinema;
export const getSeveralCinemasAboutData = (state: IReduxState) => state.appReducer.severalCinemasAboutData;
export const getHalls = (state: IReduxState) => state.appReducer.halls;
export const getSelectedDay = (state: IReduxState) => state.appReducer.selectedDay;
export const getNewsOthers = (state: IReduxState, newsItemId: string) =>
	(_.reject(state.newsReducer.news, (news) => news.id === newsItemId)).slice(0, 4);
export const getPopupOptions = (state: IReduxState) => state.appReducer.popupOptions;
export const isFetching = (state: IReduxState) => state.appReducer.isFetching;
export const isMobileSelector = (state: IReduxState) => state.appReducer.isMobile;
export const isMobile = () => document.documentElement.clientWidth <= MOBILE_MAX_SIZE;
export const isTablet = () => document.documentElement.clientWidth <= TABLET_MAX_SIZE;
export const getSoonPageIsEnabled = (state: IReduxState) => getCinema(state).soonPageIsEnabled;
export const getIsWidgetDisabled = (state: IReduxState) => !!getCinema(state)?.isWidgetDisabled;
export const getIsMonitor = (state: IReduxState) => !!getCinema(state)?.isMonitor;
export const getIsCharlie = (state: IReduxState) => !!getCinema(state)?.isCharlie;
export const getIsArman3D = (state: IReduxState) => !!getCinema(state)?.isArman3D;
export const getIsKinomir = (state: IReduxState) => !!getCinema(state)?.isKinomir;
export const getIsKinokassa = (state: IReduxState) => !!getCinema(state)?.isKinokassa;
export const getHasCustomMapMarker = (state: IReduxState) => !!getCinema(state)?.hasCustomMapMarker;
export const hasSelectButton = (state: IReduxState) => !!getCinema(state).selectButton.options.length;
export const isFeedbackEnabled = (state: IReduxState) => state.appReducer.feedback;
export const getHiddenCinemasIDs = (state: IReduxState) => state.appReducer.hiddenCinemasIDs;
export const getHasNeedHideRefundButton = (state: IReduxState) => state.appReducer.hasNeedHideRefundButton;
export const getSmartBanner = (state: IReduxState) => state.appReducer.hasSmartBanner;
export const getIsSemiblind = (state: IReduxState) => state.appReducer.isSemiblind;
export const getFontSize = (state: IReduxState) => state.appReducer.fontSize;
export const getContactsAddresses = (state: IReduxState) => getContacts(state).addresses;
export const getContactsSocials = (state: IReduxState) => getContacts(state).socials;
export const getContactsPhones = (state: IReduxState) => getContacts(state).phones;
export const getAppErrorCode = (state: IReduxState) => state.appReducer.error?.code || null;
export const getContactsApps = (state: IReduxState) => getContacts(state).apps;
export const getCinemaOpeningHours = (state: IReduxState) => getContacts(state).cinema_opening_hours;
export const getCinemaEmail = (state: IReduxState) => getContacts(state).email;
export const getAllCities = (state: IReduxState) => state.appReducer.allCities;
export const getAllCinemas = (state: IReduxState) => state.appReducer.allCinemas;
export const getAllContacts = (state: IReduxState) => state.appReducer.allContacts;
export const getCurrentCityId = (state: IReduxState) => CITY_ID || state.appReducer.currentCityId;
export const getCityFilter = (state: IReduxState) => state.appReducer.cityFilter;
export const getIsUserAuth = (state: IReduxState) => state.appReducer.isUserAuth;
export const getAppCountryCode = (state: IReduxState) => state.appReducer.country?.code;
export const getIsWidgetInKioskMode = (state: IReduxState) => state.appReducer.isWidgetInKioskMode;
export const getCinemasModules = (state: IReduxState) => state.appReducer.modules;
export const getCountUnreadNotifications = (state: IReduxState) => state.appReducer.countUnreadNotifications;
export const getWithNewDesign = (state: IReduxState) => !!getCinema(state)?.withNewDesign || false;
export const getHasNeedUseRoutingWithoutCityId = (state: IReduxState) => state.appReducer.hasNeedUseRoutingWithoutCityId;

export const isFondKino = !(kinositeCinemaId && CITY_ID);

export const getApps = createSelector(
	[getCinema, getContactsApps],
	({ app: { appstore, googleplay } }, { apple, google }) => ({
		appstore: apple || appstore,
		googleplay: google || googleplay,
	}),
);

export const getCinemaLangsOptions = createSelector(
	[getAppCountryCode],
	(country) => {
		const getLanguageByCountryCode = () => {
			switch (country) {
				case "KZ":
					return SUPPORTED_LANGS.KK;
				default:
					return SUPPORTED_LANGS.RU;
			}
		};

		return _.unique([SUPPORTED_LANGS.RU, getLanguageByCountryCode()]).map(value => ({
			title: i18n.t(`lang.${value}`),
			value,
		}));
	}
);

export const getFilteredPhones = createSelector(
	[getContactsPhones],
	phones => phones.filter(phone => phone.value),
);

export const getErrorsCodeConvention = (code: AppErrorCode): {
	text: string;
	img?: string;
	description?: string;
} => {
	const text = i18n.t(`ErrorCode.${code}`);
	const description = [202, 304, 332, 329, 340] ? i18n.t(`ErrorCode.${code}_desc`) : undefined;

	switch (code) {
		case 100:
		case 200:
		case 301:
		case 303:
		case 308:
		case 313:
			return { text };
		case 101:
			return {
				img: '/img/lock.svg',
				text,
			};
		case 201:
			return {
				img: '/img/wrench.svg',
				text,
			};
		case 202:
			return {
				text,
				img: '/img/server-no.svg',
				description,
			};
		case 300:
		case 302:
			return {
				text,
				img: '/img/zoom.svg',
			};
		case 304:
		case 340:
			return {
				text,
				description,
			};
		case 314:
			return {
				text,
				img: '/img/no.svg',
			};
		case 329:
		case 332:
			return {
				img: '/img/wallet.svg',
				text,
				description,
			};
		default: return { text };
	}
};

export const getErrorMessage = createSelector(
	[getAppErrorCode],
	(errorCode) => errorCode ? getErrorsCodeConvention(errorCode) : null,
);

export const getFooterPhones = createSelector(
	[getCinema, getContactsPhones],
	({ phoneNumbers }, contactsPhones) => contactsPhones.length ?
		_.compact(_.pluck(contactsPhones, 'value'))
		: phoneNumbers,
);

export const getSocials = createSelector(
	[getCinema, getContactsSocials],
	(cinema, socials) => {
		const {
			facebook, instagram, twitter,
			vk, odnoklassniki: ok, youtube,
		} = cinema;

		if (!_.isEmpty(socials)) {
			return ({
				facebook: socials.facebook,
				instagram: socials.instagram,
				twitter: socials.twitter,
				vk: socials.vk,
				ok: socials.ok,
				youtube: socials.youtube,
				telegram: socials.telegram,
			});
		}

		return _.pick({ facebook, instagram, twitter, vk, ok, youtube }, link => !!link);
	},
);

export const getAppsForMonitor = createSelector(
	[getAllContacts],
	(contacts) => contacts.reduce((memo, contact) => {
		const apps = contact.apps.reduce((memo, app) => {
			memo[app.name === 'google' ? 'googleplay' : 'appstore'] = app.value;

			return memo;
		}, { googleplay: '', appstore: '' });

		return {
			appstore: apps.appstore || memo.appstore,
			googleplay: apps.googleplay || memo.googleplay,
		};
	}, { googleplay: '', appstore: '' }),
);

export const getAddresses = createSelector(
	[getCinema, getContactsAddresses],
	(cinema, addresses) => {
		const { address, place, maps } = cinema;
		const { main, place: additionalPlace, mapsLink } = addresses;

		return {
			address: main || address,
			place: additionalPlace || place,
			maps: mapsLink || maps,
		};
	},
);

export const isSmartBannerShown = createSelector(
	[getSmartBanner, getApps],
	(smartBanner, { googleplay }) => {
		if (smartBanner) {
			const smart = localStorage.getItem('smart');

			if (isMobile() && smart !== 'close' && !!googleplay) {
				return navigator.userAgent.search('Android') !== -1;
			}

			return false;
		}

		return smartBanner;
	},
);

export const getBusinessDate = (momentDate: Moment) => (
	moment().hour() < BUSINESS_DAY_END && momentDate.isSame(moment(), 'day')
		? momentDate.add(1, 'day')
		: momentDate
);

export const getBusinessDay = (offsetFromCurrentDay = 0) => {
	const currentBusinessDay = moment().hour() < BUSINESS_DAY_END ? moment().subtract(1, 'day') : moment();

	return currentBusinessDay.add(offsetFromCurrentDay, "days")
};

export const getCurrentCinemas = createSelector(
	[getAllCinemas, getCurrentCityId, getIsMonitor],
	(cinemas, cityId, isMonitor) =>
		(!isMonitor ? cinemas : sortMonitorCinemas(cinemas))
			.filter(cinema => cinema.city_id === cityId),
);

export const getIsSeveralCinemasInCity = createSelector(
	[getCurrentCinemas],
	(cinemas) => cinemas.length > 1,
);

export const getAboutCurrentCinema = (state: IReduxState, props) => {
	const { aboutCinema, severalCinemasAboutData } = state.appReducer;
	const isSeveralCinemas = getIsSeveralCinemasInCity(state);

	if (!isSeveralCinemas) {
		return aboutCinema;
	}

	const { match: { params } } = props;
	const currentCinemaAboutData = _.findWhere(severalCinemasAboutData, { cinema_id: Number(params.cinemaId) });

	return currentCinemaAboutData?.about;
};

export const makeGetAboutCurrentCinema = () => (
	createSelector(
		[getAboutCurrentCinema],
		aboutCurrentCinema => aboutCurrentCinema,
	)
);

export const getIsAllPromosEnabled = createSelector(
	[getCurrentCinemas, getIsSeveralCinemasInCity],
	(cinemas, isSeveralCinemasInCity) =>
		isSeveralCinemasInCity && _.some(cinemas, cinema => cinema.modules.promotions_enabled === true),
);

export const getModules = createSelector(
	[getCinema, getCurrentCinemas],
	(cinema: Cinema, currentCinemas: Cinema[]) => {
		if (!currentCinemas.length) return cinema.modules;

		return currentCinemas.reduce<Modules>((memo, { modules }) => {
			Object.keys(memo).map(key => {
				memo[key] = memo[key] || modules[key];
			});

			return memo;
		}, currentCinemas[0]?.modules ?? {
			articles_enabled: false,
			bar_enabled: false,
			branding_enabled: false,
			goods_enabled: false,
			in_hall_order_enabled: false,
			promotions_enabled: false,
			subsale_enabled: false,
		});
	},
);

export const getSectionDisplaySettingsForCity = createSelector(
	[getCinema, getAllCinemas, getCurrentCityId],
	(cinema, cinemas, cityID): SectionDisplaySettings => {
		const sectionSettingsTemplate = { imax: false, vip: false, pro_culture: false, not_movie: false };

		return cinemas.length > 1
			? cinemas.reduce<SectionDisplaySettings>((memo, cinema) => {
				if (cinema.city_id === cityID) {
					memo.imax = memo.imax || cinema.display_settings.section_display_settings.imax;
					memo.vip = memo.vip || cinema.display_settings.section_display_settings.vip;
					memo.pro_culture = memo.pro_culture || cinema.display_settings.section_display_settings.pro_culture;
					memo.not_movie = memo.not_movie || cinema.display_settings.section_display_settings.not_movie;
				}

				return memo;
			}, sectionSettingsTemplate)
			: cinema.display_settings?.section_display_settings ?? sectionSettingsTemplate;
	});

export const getIsCitySelectShown = createSelector(
	[getAllCities],
	cities => cities.length > 1,
);

const getMonitorMenuLinks = createSelector([
	getModules,
	getSoonPageIsEnabled,
	getAboutCinema,
	getCustomPages,
	getIsAllPromosEnabled,
	getIsSeveralCinemasInCity,
	getHalls,
	getIsMonitor,
	getSectionDisplaySettingsForCity,
	getCurrentCityId,
	getIsCitySelectShown,
	hasProCultureSeanses,
], (
	modules,
	soonPageIsEnabled,
	aboutCinema,
	customPages,
	isAllPromosEnabled,
	isSeveralCinemasInCity,
	halls,
	isMonitor,
	sectionDisplaySettings,
	hasProCultureSeanses,
) => {
	const currentMenuLinks = [...getAppMenuLinks()];

	if (sectionDisplaySettings.imax) {
		currentMenuLinks.push(getImaxLinkMonitor());
	}

	if (sectionDisplaySettings.vip) {
		currentMenuLinks.push(getVipLinkMonitor());
	}

	if (sectionDisplaySettings.pro_culture && hasProCultureSeanses) {
		currentMenuLinks.push(getKinositeSectionLink(KinositeSections.PUSHKIN_CARD));
	}

	if (sectionDisplaySettings.not_movie) {
		currentMenuLinks.push(getKinositeSectionLink(KinositeSections.NOT_MOVIES));
	}

	if (soonPageIsEnabled) {
		currentMenuLinks.push(getKinositeSectionLink(KinositeSections.SOON));
	}

	if (
		(!isSeveralCinemasInCity || isMonitor) && modules && modules.articles_enabled
	) {
		currentMenuLinks.push(getKinositeSectionLink(KinositeSections.NEWS));
	}

	if (isAllPromosEnabled || (!isSeveralCinemasInCity && modules && modules.promotions_enabled)) {
		currentMenuLinks.push(getKinositeSectionLink(KinositeSections.PROMO));
	}

	if (!isSeveralCinemasInCity && (checkIfAboutCinemaNotEmpty(aboutCinema) || halls.length)) {
		currentMenuLinks.push(getKinositeSectionLink(KinositeSections.ABOUT_CINEMA));
	}

	if (window.kinosite.additionalModules?.gosuslugiPage) {
		currentMenuLinks.push(getKinositeSectionLink(KinositeSections.GOSUSLUGI));
	}

	if (!isSeveralCinemasInCity) {
		currentMenuLinks.push(getKinositeSectionLink(KinositeSections.CONTACTS));
	}

	if (isSeveralCinemasInCity) {
		currentMenuLinks.push(getKinositeSectionLink(KinositeSections.CINEMAS));
	}

	return [...currentMenuLinks, ...customPages];
});

const getKinokassaMenuLinks = createSelector(
	[
		getModules,
		getSoonPageIsEnabled,
		getAboutCinema,
		getCustomPages,
		getIsAllPromosEnabled,
		getIsSeveralCinemasInCity,
		getHalls,
		getCurrentCityId
	],
	(
		modules,
		soonPageIsEnabled,
		aboutCinema,
		customPages,
		isAllPromosEnabled,
		isSeveralCinemasInCity,
		halls,
		currentCityId
	) => {
		const currentMenuLinks = [...getAppMenuLinks()];

		if (soonPageIsEnabled) {
			currentMenuLinks.push(getKinositeSectionLink(KinositeSections.SOON));
		}

		if (isAllPromosEnabled || (modules && modules.promotions_enabled)) {
			currentMenuLinks.push(getKinositeSectionLink(KinositeSections.PROMO));
		}

		if (!isSeveralCinemasInCity && (checkIfAboutCinemaNotEmpty(aboutCinema) || halls.length)) {
			currentMenuLinks.push(getKinositeSectionLink(KinositeSections.ABOUT_CINEMA));
		}

		if (modules && modules.articles_enabled) {
			currentMenuLinks.push(getKinositeSectionLink(KinositeSections.NEWS));
		}

		if (isSeveralCinemasInCity) {
			currentMenuLinks.push(getKinositeSectionLink(KinositeSections.CINEMAS));
		} else {
			currentMenuLinks.push(getKinositeSectionLink(KinositeSections.CONTACTS));
		}

		const links = [...currentMenuLinks, ...customPages];

		return transformMenuLinks(links, currentCityId);
	},
);

const getDefaultMenuLinks = createSelector(
	[
		getModules,
		getSoonPageIsEnabled,
		getAboutCinema,
		getIsAllPromosEnabled,
		getIsSeveralCinemasInCity,
		getHalls,
		getSectionDisplaySettingsForCity,
		hasProCultureSeanses,
	],
	(
		modules,
		soonPageIsEnabled,
		aboutCinema,
		isAllPromosEnabled,
		isSeveralCinemasInCity,
		halls,
		sectionDisplaySettings,
		hasProCultureSeanses,
	) => {
		const currentMenuLinks = [...getAppMenuLinks()];

		if (!isSeveralCinemasInCity && (checkIfAboutCinemaNotEmpty(aboutCinema) || halls.length)) {
			currentMenuLinks.splice(2, 0, getKinositeSectionLink(KinositeSections.ABOUT_CINEMA));
		}

		if (isAllPromosEnabled || modules && modules.promotions_enabled) {
			currentMenuLinks.splice(2, 0, getKinositeSectionLink(KinositeSections.PROMO));
		}
		if (modules && modules.articles_enabled) {
			currentMenuLinks.splice(2, 0, getKinositeSectionLink(KinositeSections.NEWS));
		}

		if (soonPageIsEnabled) {
			currentMenuLinks.splice(2, 0, getKinositeSectionLink(KinositeSections.SOON));
		}

		if (isSeveralCinemasInCity) {
			currentMenuLinks.splice(2, 0, getKinositeSectionLink(KinositeSections.CINEMAS));
		}

		if (sectionDisplaySettings.pro_culture && hasProCultureSeanses) {
			currentMenuLinks.splice(2, 0, getKinositeSectionLink(KinositeSections.PUSHKIN_CARD));
		}

		if (sectionDisplaySettings.not_movie) {
			currentMenuLinks.splice(2, 0, getKinositeSectionLink(KinositeSections.NOT_MOVIES));
		}

		if (sectionDisplaySettings.imax) {
			currentMenuLinks.splice(2, 0, getKinositeSectionLink(KinositeSections.IMAX));
		}

		if (sectionDisplaySettings.vip) {
			currentMenuLinks.splice(2, 0, getKinositeSectionLink(KinositeSections.VIP));
		}

		if (window.kinosite.additionalModules?.gosuslugiPage) {
			currentMenuLinks.push(getKinositeSectionLink(KinositeSections.GOSUSLUGI));
		}

		if (!isSeveralCinemasInCity) {
			currentMenuLinks.push(getKinositeSectionLink(KinositeSections.CONTACTS));
		}

		return currentMenuLinks;
	}
);


const getTransformedDefaultMenuLinks = createSelector(
	[
		getDefaultMenuLinks,
		getCustomPages,
		getCurrentCityId,
		getIsCitySelectShown,
		getHasNeedUseRoutingWithoutCityId,
	],
	(
		currentMenuLinks,
		customPages,
		currentCityId,
		hasMultipleCities,
		hasNeedUseRoutingWithoutCityId
	) => {
		let links: IMenu[] = [];

		const menuLinksSortOrderMap = window.kinosite.customization?.menuLinksSortOrder;

		if (menuLinksSortOrderMap) {
			links = currentMenuLinks.concat(customPages).sort((a, b) => {
				const orderOfFirstItem = menuLinksSortOrderMap[a.href] || MENU_LINK_DEFAULT_SORT_ORDER;
				const orderOfSecondItem = menuLinksSortOrderMap[b.href] || MENU_LINK_DEFAULT_SORT_ORDER;

				return orderOfFirstItem - orderOfSecondItem;
			})
		} else {
			links = [
				..._.without(currentMenuLinks, _.last(currentMenuLinks)),
				...customPages,
				_.last(currentMenuLinks),
			] as IMenu[];
		}

		return hasMultipleCities && !hasNeedUseRoutingWithoutCityId ? transformMenuLinks(links, currentCityId) : links;
	}
);

export const getMenuLinks = (state: IReduxState) => {
	const isMonitor = getIsMonitor(state);
	const isKinokassa = getIsKinokassa(state);

	let currentMenuLinksSelector: (state: IReduxState) => IMenu[];

	switch (true) {
		case isMonitor:
			currentMenuLinksSelector = getMonitorMenuLinks;
			break;
		case isKinokassa:
			currentMenuLinksSelector = getKinokassaMenuLinks;
			break;
		default:
			currentMenuLinksSelector = getTransformedDefaultMenuLinks;
	}

	return currentMenuLinksSelector(state);
}

export const hasAboutCinema = createSelector(
	getAboutCinema,
	(aboutCinema) => !!aboutCinema,
);

export const isKinokassaApp = createSelector(
	getApps,
	({ googleplay }) => !!googleplay && googleplay.indexOf('kinokassa') !== -1,
);

export const getCurrentCinemaId = createSelector(
	[getAllCinemas, getCurrentCityId],
	(cinemas, cityId) => {
		if (kinositeCinemaId) {
			return kinositeCinemaId;
		}

		const currentCityCinemas = _.where(cinemas, { city_id: cityId });

		return currentCityCinemas.length === 1 ? currentCityCinemas[0].id : null;
	},
);

export const getSocialsForMonitor = createSelector(
	[getAllContacts, getCurrentCinemaId],
	(contacts, currentCinemaID) => {
		const singleCinemaContacts = currentCinemaID
			? contacts.find(contact => contact.cinema_id === currentCinemaID)
			: contacts[0];

		return singleCinemaContacts?.socials.reduce((memo, social) => {
			if (social.value) {
				memo[social.name] = social.value;
			}

			return memo;
		}, {});
	},
);

export const getCurrentCityTitle = createSelector(
	[getAllCities, getCurrentCityId],
	(cities, cityId) => {
		const currentCity = _.findWhere(cities, { id: cityId });

		return currentCity?.title;
	},
);

export const getFilteredCities = createSelector(
	[getAllCities, getCityFilter],
	(cities, cityFilter) => {
		const filter = cityFilter.toLowerCase().trim();

		const filteredCities = cities.filter(({ title = '', region = '' }) => (
			_.some([title, ...region.split(' ')], item => item.toLowerCase().includes(filter))
		));

		const sortedCities = filteredCities.sort((a, b) => {
			const aIndex = a.title.toLowerCase().lastIndexOf(cityFilter.toLowerCase(), 0);
			const bIndex = b.title.toLowerCase().lastIndexOf(cityFilter.toLowerCase(), 0);

			if (aIndex < bIndex) return 1;
			if (aIndex > bIndex) return -1;

			return 0;
		});

		return sortedCities;
	},
);

export const getCurrentCinemaInfoSectionsLinks = createSelector(
	[
		getCurrentCinemas,
		getSeveralCinemasAboutData,
		getHalls,
		getIsSeveralCinemasInCity,
		getIsCitySelectShown,
		getCurrentCityId,
		getIsMonitor,
		(_, cinemaId: number, cinemaIdFromRoute?: string) => cinemaId || Number(cinemaIdFromRoute)],
	(
		currentCinemas, severalCinemasAboutData, halls, isSeveralCinemasInCity,
		hasMultipleCities, cityId, isMonitor, currentCinemaId
	) => {
		const hasAboutData = checkIfAboutCinemaNotEmpty(_.findWhere(severalCinemasAboutData, { cinema_id: currentCinemaId }));
		const currentCinema = _.findWhere(currentCinemas, { id: currentCinemaId });
		const hasNews = currentCinema?.modules?.articles_enabled;
		const hasPromo = currentCinema?.modules?.promotions_enabled;

		const infoSectionsLinks: IMenu[] = _.compact([
			hasAboutData && {
				id: 'about',
				href: `/cinemas/${currentCinemaId}/about`,
				title: getKinositeSectionName(KinositeSections.ABOUT_CINEMA) || i18n.t('AboutCinema'),
			},
			halls.length && {
				id: 'halls',
				href: currentCinemaId ? `/cinemas/${currentCinemaId}/halls` : '/cinema/halls',
				title: getKinositeSectionName(KinositeSections.HALLS),
			},
			hasNews && {
				id: 'news',
				href: `/cinemas/${currentCinemaId}/news`,
				title: getKinositeSectionName(KinositeSections.NEWS),
			},
			hasPromo && {
				id: 'promo',
				href: `/cinemas/${currentCinemaId}/promo`,
				title: getKinositeSectionName(KinositeSections.PROMO),
			},
			isSeveralCinemasInCity && {
				id: 'contacts',
				href: `/cinemas/${currentCinemaId}/contacts`,
				title: getKinositeSectionName(KinositeSections.CONTACTS),
			},
			{
				id: 'seances',
				href: `/cinemas/${currentCinemaId}/seances`,
				title: getKinositeSectionName(KinositeSections.SEANCES),
			}
		]);

		return hasMultipleCities && !isMonitor ? transformMenuLinks(infoSectionsLinks, cityId) : infoSectionsLinks;
	}
);

export const getAboutCinemaMenuLinks = createSelector(
	[
		getHalls,
		getIsMonitor,
		getIsCitySelectShown,
		getCurrentCityId,
		getSeveralCinemasAboutData,
		getAboutCinema,
		(_, currentCinemaId) => currentCinemaId
	],
	(
		halls,
		isMonitor,
		hasMultipleCities,
		cityId,
		severalCinemasAboutData,
		aboutCinema,
		currentCinemaId
	) => {
		const hasAboutData = checkIfAboutCinemaNotEmpty(
			_.findWhere(severalCinemasAboutData, { cinema_id: currentCinemaId }) || aboutCinema,
		);

		const infoSectionsLinks: IMenu[] = [];

		if (hasAboutData) {
			infoSectionsLinks.push({
				id: 'about',
				href: currentCinemaId && !isMonitor ? `/cinemas/${currentCinemaId}/about` : '/cinema/about',
				title: getKinositeSectionName(KinositeSections.ABOUT_CINEMA),
			});
		}

		if (halls.length) {
			infoSectionsLinks.push({
				id: 'halls',
				href: currentCinemaId && !isMonitor ? `/cinemas/${currentCinemaId}/halls` : '/cinema/halls',
				title: getKinositeSectionName(KinositeSections.HALLS),
			});
		}

		return hasMultipleCities && !isMonitor ? transformMenuLinks(infoSectionsLinks, cityId) : infoSectionsLinks;
	}
);

export const prepareSeveralCinemasFooterLinks = createSelector(
	[getCurrentCinemas],
	cinemas => cinemas.map(({ id, title, address }) => ({
		id,
		title,
		address,
	})),
);

export const prepareCurrentCinemaPageData = createSelector([
	getCurrentCinemas,
	getCurrentCinemaId,
	getCurrentCityTitle,
	getAllContacts,
	getContacts,
	getCinema,
], (cinemas, currentCinemaID, city, allContacts, contacts, singleCinema) => {
	const currentCinema = cinemas.find(cinema => cinema.id === currentCinemaID) || singleCinema;
	const {
		phones = [],
		socials = [],
		addresses = [],
		img_url: cinemaImgUrl,
		coordinates,
		email,
	} = _.findWhere([...allContacts, contacts], { cinema_id: currentCinemaID }) || {};

	const transformedSocials = !_.isArray(socials)
		? _.pairs(socials).reduce<Array<{ name: string, value: string }>>((memo, link) => {
			if (link[0]) {
				return memo.concat({
					name: link[0],
					value: link[1],
				});
			}

			return memo;
		}, [])
		: null;

	const phoneNumbers = phones.length ? phones : currentCinema.phoneNumbers?.map(phone => ({
		value: phone,
		name: '',
		comment: '',
	}));

	return ({
		...currentCinema,
		city: city || (currentCinema as Cinema).city,
		cinemaImgUrl,
		coordinates,
		email,
		phoneNumbers: phoneNumbers?.filter(phone => phone.value) ?? [],
		socials: transformedSocials
			|| (socials as Array<{ name: string, value: string }>).filter(socialLink => socialLink.value),
		mapsLink: _.findWhere((addresses as Option[]), { name: 'mapsLink' }) || {},
	});
},
);

export const prepareCityCinemasPageData = createSelector(
	[getCurrentCinemas, getCurrentCityTitle, getAllContacts, getHiddenCinemasIDs],
	(
		cinemas,
		city,
		contacts,
		hiddenCinemasIDs
	) => cinemas.reduce((acc, { id, ...cinema }) => {

		if (hiddenCinemasIDs?.includes(id)) {
			return acc;
		}

		const currentContacts = _.findWhere(contacts, { cinema_id: id });
		const socials = currentContacts?.socials;
		const addresses = currentContacts?.addresses;
		const phoneNumbers = currentContacts?.phones ?? cinema.phoneNumbers?.map(phone => ({
			value: phone,
			name: '',
			comment: '',
		}));
		const cinemaImgUrl = currentContacts?.img_url;
		const coordinates = currentContacts?.coordinates;
		const email = currentContacts?.email;
		const apps = currentContacts?.apps && normalizeResponseData(currentContacts.apps);

		acc.push(({
			...cinema,
			id,
			city,
			cinemaImgUrl,
			coordinates,
			email,
			apps,
			phoneNumbers: phoneNumbers?.filter(phone => phone.value) ?? [],
			socials: socials?.filter(socialLink => socialLink.value),
			mapsLink: _.findWhere(addresses ?? [], { name: 'mapsLink' }) || {},
		}));

		return acc;
	}, [] as (
		Array<Omit<Cinema, "city" | "coordinates" | "phoneNumbers"> & {
			coordinates?: ContactData["coordinates"];
			cinemaImgUrl?: ContactData["img_url"];
			email?: ContactData["email"];
			city?: string;
			apps?: { [name: string]: string; };
			socials?: ContactData["socials"];
			mapsLink: Option | {};
			phoneNumbers: Phone[];
		}>
	)),
);

export const getCurrentCinemaApps = createSelector(
	[prepareCityCinemasPageData, (_state, cinemaID: number) => cinemaID],
	(cityCinemas, cinemaID) => cityCinemas.find(cinema => cinema.id === cinemaID)?.apps,
);

export const getCinemasForRambler = createSelector(
	[getCurrentCinemas, getCinema],
	(currentCinemas, cinema) => currentCinemas.length ? currentCinemas : [cinema],
);

export const getCurrencySymbol = createSelector(
	[getCinema],
	cinema => {
		if (cinema && cinema.payment_methods) return currencySymbols[cinema.payment_methods.currency] || currencySymbols.RUB;

		return currencySymbols.RUB;
	},
);

export const getRoutePrefix = createSelector(
	[getIsCitySelectShown, getCurrentCityId, getHasNeedUseRoutingWithoutCityId],
	(
		hasMultipleCities,
		cityId,
		hasNeedUseRoutingWithoutCityId
	) => hasMultipleCities && !hasNeedUseRoutingWithoutCityId ? `/${cityId}` : '',
);

export const isOurAcquire = createSelector(
	[getCinema],
	cinema => cinema.payment_methods?.gateway_merchant === 'kinoplan'
);

export const getAllCinemasOfRelease = createSelector(
	[getCinemas, getIsMonitor],
	(cinemas, isMonitor) => !isMonitor ? cinemas : sortMonitorCinemas(cinemas)
);

export const getCinemasIdsOfCurrentSeances = createSelector(
	[getSeancesOfReleaseItem],
	(seances) => seances.reduce((acc, {cinema_id}) => {
		acc[cinema_id] = cinema_id;
		return acc;
	}, {}) as Record<number, number>
)

export const getAllCinemasOfCurrentSeances = createSelector(
	[getCinemasIdsOfCurrentSeances, getAllCinemasOfRelease],
	(cinemasIdsOfCurrentSeances, allCinemasOfRelease) => {
		let cinemaFilterValues: IFilterValue[] = [];

		allCinemasOfRelease.forEach(({id, title}) => {
			if (cinemasIdsOfCurrentSeances[id]) {
				cinemaFilterValues.push({
					filterKey: FILTER_PANEL_ELEMENTS.CINEMA,
					id: id.toString(),
					title: title || ''
				})
			}
		});

		return cinemaFilterValues;
	}
)

export const getFilteredCinemasOfCurrentSeances = createSelector(
	[getCinemasIdsOfCurrentSeances, getAllCinemasOfRelease, getCinemasFilterValues],
	(cinemasIdsOfCurrentSeances, cinemasOfRelease, selectedCinemas) => (
		cinemasOfRelease.filter(({id}) => (
			cinemasIdsOfCurrentSeances[id] && (!selectedCinemas || selectedCinemas[id])
		))
	),
);

const getCinemasOfCurrentCity = createSelector(
	[getCurrentCinemas],
	(cinemasOfCurrentCity) => cinemasOfCurrentCity.map<IFilterValue>(({id, title}) => ({
		filterKey: FILTER_PANEL_ELEMENTS.CINEMA,
		id: String(id),
		title,
	}))
)

export const getDataForFilterValuesList = createSelector(
	[
		getAgeRatings,
		getMappedReleasesGenres,
		getReleasesFeatures,
		getAllCinemasOfCurrentSeances,
		getFilters,
		getLocalFilters,
		getCinemasOfCurrentCity,
		getCurrentPage,
		(_, filterKey: keyof IFiltersState["selectedValues"]) => filterKey,
		(_, _1, valueType: FilterValueType) => valueType,
	],
	(
		ageRatings,
		genres,
		features,
		cinemasOfCurrentSeances,
		filters,
		localFilters,
		cinemasOfCurrentCity,
		page,
		filterKey,
		valueType
	) => {
		const currentFilters = valueType === FilterValueType.MAIN ? filters : localFilters;

		switch (filterKey) {
			case FILTER_PANEL_ELEMENTS.AGE:
				return {
					values: ageRatings,
					selectedValues: currentFilters[filterKey],
					allButtonTitle: i18n.t("Any")
				};
			case FILTER_PANEL_ELEMENTS.GENRE:
				return {
					values: genres,
					selectedValues: currentFilters[filterKey],
					allButtonTitle: i18n.t("All")
				};
			case FILTER_PANEL_ELEMENTS.FEATURES:
				return {
					values: features,
					selectedValues: currentFilters[filterKey],
					allButtonTitle: i18n.t("All")
				};
			case FILTER_PANEL_ELEMENTS.CINEMA:
				return  {
					values: page === FiltersPage.DAILY_SEANCES ? cinemasOfCurrentCity : cinemasOfCurrentSeances,
					selectedValues: currentFilters[filterKey],
					allButtonTitle: i18n.t("All")
				};
			case FILTER_PANEL_ELEMENTS.SORTING:
				return {
					values: getSortTypesLabels(),
					selectedValues: {
						[currentFilters[filterKey]]: currentFilters[filterKey]
					},
				};
			default: {
				return {
					values: [] as IFilterValue[],
					selectedValues: null,
					allButtonTitle: i18n.t("All")
				}
			}
		}
	}
);

export const getCurrentMobileFilterPopupElements: (state: IReduxState) => FilterPanelElementsType[] = createSelector(
	[
		getAgeRatings, getMappedReleasesGenres, getReleasesFeatures,
		getCurrentPage, getIsSeveralCinemasInCity, getAllCinemasOfCurrentSeances
	],
	(
		ageRatings, genres, features,
		currentPage, isSeveralCinemasInCity, allCinemasOfCurrentSeance,
	) => {
		switch (true) {
			case currentPage === FiltersPage.RELEASE:
				return _.compact([
					allCinemasOfCurrentSeance.length > 1 && FILTER_PANEL_ELEMENTS.CINEMA,
				]);
			case currentPage === FiltersPage.DAILY_SEANCES:
				return _.compact([
					isSeveralCinemasInCity && FILTER_PANEL_ELEMENTS.CINEMA,
				]);
			case currentPage === FiltersPage.SOON:
			case currentPage === FiltersPage.AFISHA:
				return _.compact([
					FILTER_PANEL_ELEMENTS.PUSHKIN_CARD,
					!isSeveralCinemasInCity && currentPage !== FiltersPage.SOON && FILTER_PANEL_ELEMENTS.SORTING,
					genres.length > 1 && FILTER_PANEL_ELEMENTS.GENRE,
					ageRatings.length > 1 && FILTER_PANEL_ELEMENTS.AGE,
					features.length > 1 && FILTER_PANEL_ELEMENTS.FEATURES,
				])
			default:
				return [];
		}
	}
);

export const getCurrentFilterPanelElements: (state: IReduxState) => FilterPanelElementsType[] = createSelector(
	[isMobileSelector, getCurrentMobileFilterPopupElements],
	(isMobile, mobilePopupElements) => {
		switch (true) {
			case isMobile:
				return _.compact([
					FILTER_PANEL_ELEMENTS.CALENDAR_MOBILE,
					mobilePopupElements.length && FILTER_PANEL_ELEMENTS.MOBILE_ALL_FILTERS,
				]);
			default:
				return [
					FILTER_PANEL_ELEMENTS.CALENDAR,
					...mobilePopupElements
				];
		}
	}
);
